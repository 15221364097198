import React, { useContext } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/seo"
import NaviContext from '../../context/NaviContext'
import Modal from '../../components/Modal'
import Footer from '../../components/Footer'

//import WPDefault from "../components/Content/WP/Default"
import ACF from "../../components/Content"
import MRUEnrollModal from "../../components/Modal/MRUEnroll"

export default ({ data, pageContext }) => {
	const naviContext = useContext(NaviContext)
  const footerImage = data?.wordpressAcfOptions.options.footer_image?.localFile?.childImageSharp.fluid

  return (
    <>
      <div id="mainframe-cover" className="layer" onClick={() => { naviContext.activeToggle(true); naviContext.hamburgerActiveToggle(true)}} onKeyDown={() => { naviContext.activeToggle(true); naviContext.hamburgerActiveToggle(true)}} role="button" tabIndex={0}>
        <div id="mainframe-bg-overlay" className="layer"></div>
        <div id="mainframe-stripes" className="layer">
          
        </div>
      </div>
      <Modal trigger={"#modal-mru-enroll"}>
        <MRUEnrollModal />
      </Modal>
      <main id="mainframe" className={`c0 main-${pageContext.slug === "/" ? "frontpage" : pageContext.slug}`}>

        <SEO title={data.wordpressPage?.title} description="Description" />
        
        { data.wordpressPage?.acf.sections_page ?
          <ACF { ...data } />
        : null }

        {/* { data.wordpressPage.content ?
          <WPDefault key="WP-default-1" { ...data } />
        : null } */}

        <Footer image={footerImage} />
  
      </main>
    </>
  )
}

export const query = graphql`

  query($id: String!) {
    wpgraphql {
      ...wpNaviPrimary
      ...wpNaviSecondary
    }
    wordpressPage( id: { eq: $id } ) {
      id
      title
      slug
      date
      author
      yoast_head
      acf {
        sections_page {
          ...acfContent
        }
      }
    }
    wordpressAcfOptions {
      options {
        footer_image {
          localFile {
            ...imgStandard
          }
        }
      }
    }
  }
`