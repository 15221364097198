import React, { useContext } from 'react'
import './style.scss'
import Logo from '../../Header/Logo'
import ModalContext from '../../../context/ModalContext'
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

function MRUEnrollModal() {
  const modalContext = useContext(ModalContext)

  const { file } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "mru-pop-up.jpg" }) {
          childImageSharp {
            fluid (
              maxWidth: 1280,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )


  return (
    <div id="enroll-modal">
      <Link to="/archives/academic/bachelor-of-science-in-applied-behavior-analysis-bs-aba" className="grid-12 c4">
        <div style={{ paddingBottom: "88%", minWidth: "12em" }} className='aspect-ratio'>
          <Img fluid={file.childImageSharp.fluid}
            imgStyle={{ objectFit: 'cover' }}
            objectPosition='50% 50%'
            alt='ABA Pop Up Link Image'
          />
        </div>
      </Link>

      {/* <div className="span-5 span-12-mobile">
        <div className="image-wrap">
          <div className="bg">
            <img src="https://cms.mru.edu/wp-content/uploads/2021/08/MRU-banner-News-testimonial2.jpg" />
          </div>
        </div>
      </div>
      <div className="span-7 span-12-mobile text-center uppercase padd-2">
        <h1><span className="yellow">Success is</span><span className="bigger-font"><br />one step<br />away!</span></h1>
        <div className="inside-box padd">
          <h6 className="yellow on-top-border c4 ">Offering degrees in</h6>
          <div className="single-item">
            <h4>Nursing</h4>
            <p className="yellow">ASN, BSN, MSN</p>
          </div>
          <div className="single-item">
            <h4>Master of Education</h4>
            <p className="yellow">In Educational Administration</p>
          </div>
          <div className="single-item">
            <h4>Master of Business Administration</h4>
          </div>
          <div className="on-bottom-border c4">
            <a href="#home-request-information" className="btn" onClick={() => { modalContext.setActive(false); modalContext.setIsClosed(true) }}><span>Enroll Now</span></a>
          </div>
        </div>
        <div className="logo padd padd-top-2">
          <Logo />
        </div>
      </div> */}
    </div>
  )
}

export default MRUEnrollModal

